@mixin heading-print {
  color: #000;
  line-height: 1;
  page-break-after: avoid;
  orphans: 3;
  widows: 3;
}

h1 {
  @include heading-print;
  font-size: 20pt;
}

h2 {
  @include heading-print;
  font-size: 18pt;
}

h3 {
  @include heading-print;
  font-size: 16pt;
}

h4 {
  @include heading-print;
  font-size: 15pt;
}
