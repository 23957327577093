iframe,
object,
video {
  display: none;
}

main {
  td,
  li,
  p {
    @include content-text-print;
  }
}

blockquote {
  quotes: '\201E' '\201D';

  p {
    font-style: italic;

    &:first-of-type::after {
      content: close-quote;
    }

    &:last-of-type::before {
      content: open-quote;
    }
  }
}
