.c-list--count {
  list-style: none;
  counter-reset: count-me;

  > li {
    counter-increment: count-me;

    &::before {
      color: #000;
      content: counter(count-me) '.';
      text-align: right;
      display: block;
      position: absolute;
      width: 50px;
      left: -60px;
      top: 0;
    }
  }
}
